.belarus-country-icon {
    background-image: url("../../../public/locationsIcons/belarus.png");
}
.cyprus-country-icon {
    background-image: url("../../../public/locationsIcons/cyprus.png");
}
.georgia-country-icon {
    background-image: url("../../../public/locationsIcons/georgia.png");
}
.lithuania-country-icon {
    background-image: url("../../../public/locationsIcons/lithuania.png");
}
.montenegro-country-icon {
    background-image: url("../../../public/locationsIcons/montenegro.png");
}
.poland-country-icon {
    background-image: url("../../../public/locationsIcons/poland.png");
}
.russia-country-icon {
    background-image: url("../../../public/locationsIcons/russia.png");
}
.ukraine-country-icon {
    background-image: url("../../../public/locationsIcons/ukraine.png");
}
.other-country-icon {
    background-image: url("../../../public/locationsIcons/nuts.png");
}