html, body {
  height: 100%;
  min-height: 100%;
}

a:hover,a:visited {
  color: #922d0f;
}
a {
  color: #e74415;
}
.MuiContainer-root {
  width: 1500px;
  padding-bottom: 100px;
}
.MuiContainer-root.MuiContainer-maxWidthLg {
  width: 1200px;
}
.navbar-container {
  padding-bottom: 2px;
}

#root {
  display: block;
  background-image: url("/src/img/bg.png");
  background-size: 30%, 30%;
  background-repeat: repeat;
  min-height: 100%;
  min-width: 100%;
  position: relative;

}
footer {
  position: absolute;
  bottom:0;
  padding: 20px;
  background-color: #e74415;
  width: auto;
  left: 0;
  right: 0;
  color:#fff
}
.MuiButton-root {
  font-family: OpenSans, serif;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    background-color: #e74415;
  }

.MuiButton-root.MuiButton-contained.MuiButton-containedWarning{
  background-color: #e74415;
  border: 2px #fff solid;
  border-radius: 10px;
  box-shadow:0 3px 0 1px #a63110;
  font-size: 16px;
  font-weight: bold;
}

.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary {
  border: 2px #fff solid;
  border-radius: 5px;
  background-color: #e3e0dc;
  color:#ffffff;
  padding: 4px 10px;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary {
  background-color:#f9d0b7;
  border: 2px #fff solid;
  color:#fff;
  box-shadow:0 3px 0 1px #ccc;
  pointer-events: auto;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}

.MuiButton-root.MuiButton-outlined.MuiButton-outlinedWarning{
  background-color: #e74415;
  color: #fff;
  border-radius: 5px;
  padding: 4px 10px;
}
.MuiButton-root.MuiButton-outlined.MuiButton-outlinedWarning.Mui-disabled{
  background-color: #e3e0dc;
  color: #ffffff;
  border-color: transparent;
  pointer-events: auto;
}
.MuiButton-root.MuiButton-contained.MuiButton-containedWarning.Mui-disabled {
  background-color: #f9d0b7;
  color:#fff;
  box-shadow:0 3px 0 1px #ccc;
  pointer-events: auto;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedError {
  background-color: rgba(255, 255, 255, 0.67);
  border: 2px #e74415 solid;
  border-radius: 10px;
  box-shadow: 0 3px 0 1px #a63110;
  font-size: 16px;
  font-weight: bold;
  color:#e74415
}
.MuiButton-root.MuiButton-contained.MuiButton-containedError.Mui-disabled {
  background-color:#f9d0b7;
  border: 2px #fff solid;
  color:#fff;
  box-shadow:0 3px 0 1px #ccc;
  pointer-events: auto;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  border-radius: 5px;
  background-color: #03907f;
  color:#ffffff;
  padding: 4px 10px;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSecondary {
  border-radius: 5px;
  background-color: #4b4e4e;
  color:#ffffff;
  padding: 4px 10px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
  background-color: #03907f;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:disabled {
  background-color: #4b4e4e;
  pointer-events: auto;
}
.header__username {
  border-radius: 10px;
  background-color: #922d0f;
  margin-right: 10px;
  display: inline-block;
  padding: 10px 15px;
  line-height: 22px;
}
img {
}

.page-main-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rules-main-box {
  display: flex;
  flex-direction: column;
}
span.inline-price {
  position: relative;
  bottom: 2px;
  font-weight: bold;
}
.inline-price img {
  width: 16px;
  position: relative;
}
.product-card__price, .navbar__balance {
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: default;
  outline: 0;
  text-decoration: none;
  border: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
}
.product-card__price span, .navbar__balance span {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 2px;
}
.product-card__price span {

}
.product-page-price.product-card__price span {
  font-size: 18px;
  font-weight: bold;
  color: #EB3A00;
  position: relative;
  top: 2px;
}
.product-card-price.MuiCardContent-root:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.navbar__balance span {
  color: #EB3A00;
  font-size: 20px;
}
.product-card__price img, .navbar__balance.product-card__price img {
  margin-left: 4px;
  margin-right: 4px;
  width: 16px;
}

.product-card__price img {
  margin-left: 4px;
  margin-right: 4px;
  width: 20px;
  position: relative;
  top: 2px;
}
.navbar__balance img {
  width: 32px;
}

.MuiFormLabel-colorPrimary.balance-legend {
  color: #e74415;
  font-size: 14px;
  padding-left: 12px;
}
.MuiFormControl-root.balance-form {
  position: relative;

  padding: 3px 6px 3px 1px;
  border-radius: 4px;
}
.MuiFormControl-root.balance-form label {
  position: absolute;
  top:-8px;
  font-size: 12px;
  background-color: #fff;
  display: none;
  margin: 0;
  padding: 0;
  left:8px
}
.MuiTabs-root.menu a {
  font-weight: bold;
}
.MuiTabs-root.menu a>span {
  background-size: 25px;
  background-repeat: no-repeat;
  height: 25px;
  width: 100%;
  background-position: center;
  display: inline-block;
}

.menu__seniority { background: url("../img/CoinBlack.png"); }
.Mui-selected .menu__seniority { background-image: url("../img/CoinOrange.png"); }

.menu__shop { background: url("../img/ShopBlack.png"); }
.Mui-selected .menu__shop { background-image: url("../img/ShopOrange.png"); }

.menu__balance { background: url("../img/BalansBlack.png"); }
.Mui-selected .menu__balance { background-image: url("../img/BalansOrange.png"); }

.menu__admin { background: url("../img/AdminBlack.png"); }
.Mui-selected .menu__admin { background-image: url("../img/AdminOrange.png"); }

.menu__rules { background: url("../img/RullBlack.png"); }
.Mui-selected .menu__rules { background-image: url("../img/RullOrange.png"); }

.menu__history { background: url("../img/HistoryBlackPers.png"); }
.Mui-selected .menu__history { background-image: url("../img/HistoryOrangePers.png"); }


.MuiTabs-flexContainer a.Mui-selected {
  color: #e74415;
}
.MuiTabs-scroller .MuiTabs-indicator {
  background-color: #e74415;
}

.text-centered {
  text-align: center;
}

.colored-title {
  background-color: #e74415;
  color:#fff;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  padding: 5px 10px;
  margin: 10px 0;
}
.MuiBox-root {
  align-items: start;
}
.MuiTabs-root {
  padding: 0;
}
.MuiCircularProgress-root.MuiCircularProgress-colorPrimary {
  color: #e74415;

}
.MuiDataGrid-root {
  background: #fff
}
.product-cards { margin: 0 -15px; width:100%}
.product-card.MuiBox-root {
  width: calc(100% * (1/4) - 20px);

  margin: 10px;
  padding: 0;
}

.admin-product-card.MuiBox-root {
  width: 100%;
  margin: 15px;
  padding: 0;
  height: 120px;
}

.admin-product-card .MuiPaper-root {
  height: 100%;
  width: 100%;
}

.droppable-field {
  width: calc(100% - 30px);
}

.admin-product-card .MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img {
  width: 10%;
}

.admin-product-card .MuiCardMedia-root {
  height: 100%;
  width: 100%;
}

.product-card button {cursor: pointer}

.product-page {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}
.product-page .product-card__price {
  margin-bottom: 10px;

}
.description-editor{

}

.description-editor-label {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: #e74415;
  margin-top: 8px;
}
.ck-content {
  min-height: 100px;
}

.error-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #e74415
}
@media (max-width: 1200px) {
  #root .MuiContainer-root {
    width: 900px;
  }
  .product-card.MuiBox-root {
    width: calc(100% * (1/3) - 30px);

    margin: 15px;
    padding: 0;
  }
  .MuiTabs-root.menu a>span {
    background-size: 18px;
    height: 18px;
  }
  .MuiTabs-root.menu a {
    font-size: 14px;
    padding: 0;
  }

  .navbar__balance img {
    width: 16px;
  }

  .MuiButton-root.MuiButton-contained.MuiButton-containedWarning,.header__username {
    font-size: 14px;
    line-height: 18px;
  }
  .product-page .MuiButton-root.MuiButton-contained.MuiButton-containedWarning {
    margin-bottom: 10px;
    margin-left: 0;
  }
}


.MuiFormControl-root.navbar__location {
  max-width: 200px; background-color: #fff
}

.MuiFormControl-root.control__location {
  max-width: 200px; background-color: rgba(255, 255, 255, 0.25)
}
.MuiCardActions-root.MuiCardActions-spacing .MuiButton-root {
  float: right;
}
.MuiCardActions-root.MuiCardActions-spacing>.MuiBox-root  {
  width: 100%;
}
.MuiCardActions-root.MuiCardActions-spacing {
  padding: 0 16px 16px 16px;

}
.MuiTypography-root.MuiTypography-h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.MuiButtonBase-root.variation__item.selected,.MuiButtonBase-root.variation__item.selected:hover {
  background-color: #e74415;
  color:#fff;
}
.MuiButtonBase-root.variation__item.selected svg {
  color:#fff !important;
}
.MuiButtonBase-root.variation__item {
  margin-right:5px;margin-bottom:5px;
  padding:3px 5px;
  cursor: pointer;
}
.MuiSvgIcon-root  {
  color: #e77250;
}
.MuiDataGrid-root.MuiDataGrid-main.MuiDataGrid-row.Mui-selected {
  background-color: rgba(231, 68, 21, 0.08);
}
.MuiChip-root.MuiChip-filled.MuiChip-sizeSmall.MuiChip-colorWarning{
  background-color: #e74415;
}
.MuiInputLabel-outlined.MuiFormLabel-colorWarning.MuiFormLabel-filled {
  color: #e74415;
  border-color: #e74415;
}
.MuiInputLabel-outlined.MuiFormLabel-colorWarning.MuiFormLabel-filled {
  color: #e74415;
  border-color: #e74415;
}
.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorPrimary {
  background-color: #03907f;
  color:#fff;
}
.MuiChip-root.MuiChip-outlined.MuiChip-sizeSmall.MuiChip-colorPrimary {
  border: 1px solid rgba(3, 144, 127, 0.6);
  color: #03907f;
}
.MuiChip-root.MuiChip-outlined.MuiChip-sizeSmall.MuiChip-colorSecondary {
  border: 1px solid rgba(3, 144, 127, 0);
  color: #757979;
}
.MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium.MuiChip-colorPrimary {
  border: 1px solid rgba(3, 144, 127, 0.6);
  color: #03907f;
}
.MuiChip-root.MuiChip-contained.MuiChip-sizeSmall.MuiChip-colorWarning {
  background-color: #e74415;
  color:#fff;
}
.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorWarning {
  background-color: #e74415;
  color:#fff;
}
.rules-statements {
  background-color: #e74415;
}
.MuiDataGrid-columnHeaders {
  background-color: #e74415;
  color: #fff;
}
.MuiDataGrid-cell {
  border-left: 1px solid rgba(224, 224, 224, 1);
}
.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault {
  margin-left: auto;
  background-color: rgba(249, 208, 183, 0.3);
  font-weight: bold;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-icon.MuiChip-iconMedium.MuiChip-iconColorDefault {
  color: #e74415;
}

@media (max-width: 800px) {
  .MuiGrid-root>.MuiGrid-item {
    padding-left: 0 !important;
  }
  .page-main-box {
    margin: 0 -16px;
  }
  #root .MuiContainer-root {
    width: 100%;
  }
  .product-card.MuiBox-root {
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
  .MuiBox-root.navbar {
    display: block;
  }
  .MuiFormControl-root.navbar__location {
    display: block;
    max-width: 350px;
    margin-top: 16px;
  }
  .MuiFormControl-root.navbar__balance {
    display: block;
  }
}
.belka-login-bg {
  padding: 50px 50px 50px 50px;
  text-align: center;
  vertical-align: bottom;

}
.belka-login-bg img {
  padding-top: 80px;
  width: 40%;
  cursor: pointer;
  margin:auto;
}

.imageViewer-main {
  padding: 0;
  margin: -24px;
}
.imageViewer-other img, .imageViewer-main img {
  cursor: pointer;;
} 
.imageViewer-main img {
  width: 100%;
}
.imageViewer-other:after {
  clear: both;
  content: '';
  display: block;
}
.imageViewer-other img {
  width: 100%;
}
.imageViewer-other {
  padding: 5px;
}
.imageViewer-other>div {
  float: left;
  width: 33%;
  height: 120px;
  overflow: hidden;
}
.create-order__image-wrap img {
  width: 100%;
}
.create-order__image-wrap {
  padding: 0 10px 10px 0;
}

.MuiDataGrid-cellContent {
  margin-bottom: 8px;
  margin-top: 8px;
  overflow: auto;
  white-space: break-spaces !important;
  word-wrap: break-word !important;
}
.MuiDataGrid-root.MuiDataGrid-cell.MuiDataGrid-cellContent li {
  overflow: auto;
  white-space: break-spaces !important;
  word-wrap: break-word !important;
}
.MuiDataGrid-root.MuiDataGrid-cell.MuiDataGrid-cellContent div {
  overflow: auto;
  white-space: break-spaces !important;
  word-wrap: break-word !important;
}
.rules {
  white-space: break-spaces !important;
  word-wrap: break-word !important;
}
.rules-text {
  overflow: auto;
  white-space: break-spaces !important;
  word-wrap: break-word !important;
}
.MuiDataGrid-root.MuiDataGrid-cell ul {
  padding-inline-start: 8px !important;
}

table {
  margin-top: 16px;
  width: 100%;
  border-collapse: collapse;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  background-color: #fff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  letter-spacing: 0.01071em;
}

table, td, th {
  border: 1px solid rgba(224, 224, 224, 1);
}
.table-header {
  background-color: #e74415;
  color: #fff;
  margin: 32px;
}
.table-header p {
  margin: 16px;
}

td p {
  margin: 8px;
}

.product-card__price.img{
  margin-right: 2px;
}
.balance-accrual {
  color: #03907f
}
.strikeout-price {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 2px;
  left: 5px;
  color: #717575;
}

.strikeout-price img {
  margin-left: 4px;
  margin-right: 4px;
  width: 20px;
  position: relative;
  top: 2px;
}
.strikeout-price::after {
  border-bottom: 6px solid rgba(231, 68, 21, 0.78);
  border-radius: 2px;
  content: "";
  left: 0;
  line-height: 16px;
  margin-top: calc(2px / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  width: 35px;
}
.MuiPaper-root.MuiPaper-elevation {
  position: relative;
}
.sale-label {
  z-index: 1;
  font-size: 18px;
  font-weight: bold;
  background-image: url("../img/sale.png");
  background-size: 70px 105px;
  background-repeat: no-repeat;
  width: 70px;
  height: 105px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: -1px;
  top: -1px
}
.percents {
  transform: rotate(-57deg);
  padding-bottom: 36px;
  padding-left: 16px;
  color: #F1E8D4;
}

.limited-base {
  z-index: 1;
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(231, 68, 21);
  background-size: 100px 50px;
  background-repeat: no-repeat;
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.product-card-limited {
  position: absolute;
  left: -1px;
  top: 250px
}
.product-limited {
  position: absolute;
  left: -1px;
  top: 350px
}

.limited-text {
  padding-left: 4px;
  color: #F1E8D4;
}

.MuiFormLabel-root.MuiFormLabel-root.Mui-focused {
  color: #e74415;
}

.MuiAlert-root.MuiAlert-standardInfo {
  background-color: rgba(204, 204, 204, 0.25);
  color: #4b4e4e;
}

.MuiAlert-root.MuiAlert-standardWarning {
  background-color: rgba(249, 208, 183, 0.25);
  color: #4b4e4e;
}

.MuiAlert-root.MuiAlert-standardSuccess {
  background-color: rgba(249, 208, 183, 0.25);
  color: #4b4e4e;
}

.admin-instock-info.MuiAlert-root.MuiAlert-standardWarning {
  background-color: rgba(243, 95, 7, 0.22);
  color: #4b4e4e;
  border-radius: 30px;
  max-width: 600px;
  overflow: visible;
  font-size: 12px;
}

.admin-instock-info.MuiAlert-standardSuccess {
  background-color: rgba(3, 144, 127, 0);
  color: #4b4e4e;
  max-width: 600px;
  overflow: visible;
  font-size: 12px;

}
.MuiAlert-root.MuiAlert-message {
  overflow: visible;
}
.MuiTreeItem-content.Mui-selected {
  background-color: #ed6c022e !important;
}

.selected-present {
  background-color: rgba(204, 204, 204, 0.25);
}

.present {
  background-color: transparent;
}

.location-storage{
  font-weight: bold;
  color: #e77250;
}