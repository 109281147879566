.custom-size-table-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
}
.custom-size-table-dialog .MuiDialog-paperWidthSx {
    max-width: 800px;
}

.size-table-box {
    background-repeat: no-repeat;
    background-size: 800px 560px;
    height: 560px;
    width: 800px;
}
